import React, { useState } from "react"
import { Route, Routes } from "react-router-dom"
import { motion } from "framer-motion"

import Modal from "./Modal"

import { hidden, visible, exit, duration } from "../../Animations"
import sushi from "../../Images/sushi.webp"
import drawing from "../../Images/drawing.webp"
import usic from "../../Images/usic.webp"
import hackathon from "../../Images/hackathon.webp"

import A from "../../Global/styled"
import S from "./style"

const Projects = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalIndex, setModalIndex] = useState(null);
  const handleModal = async (e) => {
    const index = e.currentTarget.dataset.index;
    await setModalIndex(index);
    await setShowModal(true);
  }
  return (
    <A.Container1 
      as={motion.div} 
      initial={hidden}
      animate={visible} 
      exit={exit}
      transition={{ duration: duration }}
    >
      <S.Grid>
        <S.Project url={sushi}>
          <S.ProjectInfo>
            <S.Title>Sushi Gallery</S.Title>
            <S.Languages>Javascript | React | Papa Parse</S.Languages>
            <S.Description>A responsive website for a sushi restaurant that displays a menu with pictures, an about section, and online delivery options through food apps. The menu is easily editable by the restaurant through a google sheet document, which is sent as a JSON object to the website using the Papa Parse npm package.</S.Description>
            <S.LinkContainer>
              <S.Link href="https://sushi-gallery.firebaseapp.com/menu" target="_blank">Live Site</S.Link>
              <S.Link href="https://github.com/lukechang24/Sushi-Gallery" target="_blank">Github</S.Link>
            </S.LinkContainer>
            <S.FullScreen className="fa-solid fa-expand" data-index={0} onClick={handleModal}></S.FullScreen>
          </S.ProjectInfo>
          <S.Overlay></S.Overlay>
        </S.Project>
        <S.Project url={drawing} dark>
          <S.ProjectInfo>
            <S.Title>Accurate or Nah</S.Title>
            <S.Languages>Javascript | React | Firebase</S.Languages>
            <S.Description>An online drawing game where players draw prompts randomly generated by an assortment of player inputs and are awarded points based on how accurately they match the prompts. Players have access to a canvas, multiple colors, undo and eraser options, and a live chat system to communicate with other players.</S.Description>
            <S.LinkContainer>
              <S.Link href="https://capstone-ab1a2.firebaseapp.com/lobby" target="_blank">Live Site</S.Link>
              <S.Link href="https://github.com/lukechang24/Accurate-or-Naw" target="_blank">Github</S.Link>
            </S.LinkContainer>
            <S.FullScreen className="fa-solid fa-expand" data-index={1} onClick={handleModal}></S.FullScreen>
          </S.ProjectInfo>
          <S.Overlay></S.Overlay>
        </S.Project>
        <S.Project url={hackathon} dark>
          <S.ProjectInfo>
            <S.Title>Hackathon</S.Title>
            <S.Languages>Javascript | React | Firebase</S.Languages>
            <S.Description>A hackathon project assigned by the LA Food Policy Council, where my team used LA county's health statistics to create a website that displays data on food affordability, allowing users to filter and toggle through the statistics. The data was made readable by a data scientist, allowing the team to easily implement the data.</S.Description>
            <S.LinkContainer>
              <S.Link href="https://github.com/moonjason/LAFPC-Hackathon" target="_blank">Github</S.Link>
            </S.LinkContainer>
            <S.FullScreen className="fa-solid fa-expand" data-index={2} onClick={handleModal}></S.FullScreen>
          </S.ProjectInfo>
          <S.Overlay dark></S.Overlay>
        </S.Project>
        <S.Project url={usic}>
          <S.ProjectInfo>
            <S.Title>Usic</S.Title>
            <S.Languages>Javascript | React | Redux | APIs</S.Languages>
            <S.Description>A responsive website dedicated to helping record labels find unsigned artist. I worked as a freelance software engineer to help implement new features, fix bugs, and organize the general codebase. Redux was used all throughout this project, as it made state management much easier with all the information that needed to be passed through the components.</S.Description>
            <S.FullScreen className="fa-solid fa-expand" data-index={3} onClick={handleModal}></S.FullScreen>
          </S.ProjectInfo>
          <S.Overlay></S.Overlay>
        </S.Project>
        <S.Arrow className="fa-solid fa-caret-right"></S.Arrow>
        <S.Arrow left className="fa-solid fa-caret-left"></S.Arrow>
      </S.Grid>
      <Modal modalIndex={modalIndex} showModal={showModal} setShowModal={setShowModal} />
    </A.Container1>
  );
}

export default Projects;